import OnboardingQuestionnaireRoot, {
  Props as ComponentProps,
} from './OnboardingQuestionnaire'
import useViewModel from './useViewModel'
import { ViewModelComponentProps } from '@/utils/typeUtilities'

export type Props = ViewModelComponentProps<ComponentProps, typeof useViewModel>

export default function OnboardingQuestionnaire(props: Props) {
  const viewModel = useViewModel(props)
  return <OnboardingQuestionnaireRoot {...viewModel} />
}
