import { ConfigurableFunctionalityProvider } from '@/app/context/ConfigurableFunctionalityContext'
import OnboardingRoot from './Onboarding'

export default function Onboarding() {
  return (
    <ConfigurableFunctionalityProvider scope="onboarding">
      <OnboardingRoot />
    </ConfigurableFunctionalityProvider>
  )
}
