import { FullPageCard } from '@/app/components'
import { OnboardingQuestionnaire } from '@/app/features/onboarding'
import './styles.scss'
import { useWhiteLabelData } from '@/hooks/whitelabel/useWhiteLabelData'

export default function Onboarding() {
  const { whiteLabelData } = useWhiteLabelData()

  return (
    <>
      <FullPageCard>
        {whiteLabelData?.brandLogo && (
          <div className="flex justify-center">
            <img
              src={whiteLabelData.brandLogo}
              className="max-w-[70px] max-h-[70px]"
            />
          </div>
        )}
        <OnboardingQuestionnaire />
      </FullPageCard>
    </>
  )
}
